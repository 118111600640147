body {
  background-color: #282c34;
}
.App {
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header {
  /* min-height: 100vh; */
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
}

.App-link {
  color: #61dafb;
}


